import { Link } from "gatsby";
import React from "react";
import DecorativeCurve from "../components/DecorativeCurve";
import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout seo={{ title: "Not found" }}>
      <article className="flex flex-col items-center justify-center my-auto space-y-4 text-center h-60">
        <h1 className="text-3xl font-semibold">404</h1>
        <p>Sorry, this page doesn't exist</p>
        <p>
          <Link to="/">Go home</Link>.
        </p>
      </article>
      <DecorativeCurve />
    </Layout>
  );
};

export default IndexPage;
